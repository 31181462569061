













import { Component, Vue } from 'vue-property-decorator';
import { TopBar } from '../../layout/components';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import UserStore from '@/store/modules/user';
import { Helper } from '@/util';

@Component({
  name: 'AuthLogin',
  components: {
    TopBar,
  },
})
export default class extends Vue {
  created() {
    onAuthUIStateChange(async (authState, authData: any) => {
      if (!authData) {
        return;
      }

      if (authState === AuthState.SignedIn) {
        await UserStore.doLogin(authData?.attributes?.email);

        this.$router.push({ name: 'root' });
      }
    });
  }
  get isNonProd(): boolean {
    return !Helper.isProdEnv();
  }
}
