






import { Component, Vue } from 'vue-property-decorator';
import { VideoViewer } from '@/components';

@Component({
  name: 'ViewVideo',
  components: {
    VideoViewer,
  },
})
export default class extends Vue {
  private src = this.$router.currentRoute.query.url as string;
  private mimeType = this.$router.currentRoute.query.mimetype as string;
}
